import { Fragment, useState } from "react";
import { Avatar, Card, CardTitle, Spinner } from "../../common";
import { ReactComponent as ArrowLeftIcon } from "../../../icons/arrow-left.svg";
import "./EventFixturesList.css";
import { useQuery } from "@apollo/client";
import { EVENT } from "../../../api/events";
import { Link } from "react-router-dom";

function Fixture({ fixture }) {
  const participants = fixture?.roles
    ?.filter((r) => r.type?.name === "PARTICIPANT")
    .sort((a, b) => {
      return a.order - b.order;
    });

  const homeTeam =
    participants?.[0]?.organization || participants?.[0]?.profile;
  const awayTeam =
    participants?.[1]?.organization || participants?.[1]?.profile;

  return (
    <Link
      to={"/v2/fixtures/view/" + fixture.id}
      className="event-fixtures-list__stages__stage__list__item"
    >
      <div className="event-fixtures-list__stages__stage__list__item__team home">
        <div className="event-fixtures-list__stages__stage__list__item__team__img">
          {homeTeam.profilePhoto && <Avatar media={homeTeam.profilePhoto} />}
        </div>
        <div className="event-fixtures-list__stages__stage__list__item__team__text">
          <div className="event-fixtures-list__stages__stage__list__item__team__text__primary">
            {homeTeam?.name}
          </div>

          <div className="event-fixtures-list__stages__stage__list__item__team__text__secondary">
            @{homeTeam?.handle}
          </div>
        </div>
      </div>

      <div className="event-fixtures-list__stages__stage__list__item__seperator ">
        -
      </div>

      <div className="event-fixtures-list__stages__stage__list__item__team away">
        <div className="event-fixtures-list__stages__stage__list__item__team__img">
          {awayTeam.profilePhoto && <Avatar media={awayTeam.profilePhoto} />}
        </div>
        <div className="event-fixtures-list__stages__stage__list__item__team__text">
          <div className="event-fixtures-list__stages__stage__list__item__team__text__primary">
            {awayTeam?.name}
          </div>

          <div className="event-fixtures-list__stages__stage__list__item__team__text__secondary">
            @{awayTeam.handle}
          </div>
        </div>
      </div>
    </Link>
  );
}

function Stage({ ev, part }) {
  const fixtures =
    ev?.fixtures?.filter((f) => f.eventPart?.id === part?.id) || [];
  return (
    <div className="event-fixtures-list__stages__stage">
      {fixtures.length ? (
        <div className="event-fixtures-list__stages__stage__list">
          {fixtures.map((f) => (
            <Fixture fixture={f} />
          ))}
        </div>
      ) : (
        <div className="event-fixtures-list__stages__stage__empty">
          <div className="event-fixtures-list__stages__stage__empty__options">
            <div className="event-fixtures-list__stages__stage__empty__options__placeholder">
              No fixtures added.
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function Stages({ ev, parts }) {
  const [selectedPartIndex, setSelectedPartIndex] = useState(0);

  const selectedPart = parts?.[selectedPartIndex];
  const subparts = ev.parts.filter((p) => p.partOf?.id === selectedPart.id);

  return (
    <div className="event-fixtures-list__stages">
      <div className="event-fixtures-list__stages__header">
        <div
          className="event-fixtures-list__stages__header__navigate-btn prev"
          onClick={() => {
            if (!selectedPartIndex || !parts?.length) {
              return;
            }

            setSelectedPartIndex((prev) => prev - 1);
          }}
        >
          {!!selectedPartIndex && (
            <ArrowLeftIcon className="event-fixtures-list__stages__header__navigate-btn__icon" />
          )}
        </div>

        <div className="event-fixtures-list__stages__header__title">
          {selectedPart?.label || "Add a stage"}
        </div>

        <div
          className="event-fixtures-list__stages__header__navigate-btn next"
          onClick={() => {
            if (selectedPartIndex >= parts.length - 1 || !parts?.length) {
              return;
            }
            setSelectedPartIndex((prev) => prev + 1);
          }}
        >
          {!!parts.length && selectedPartIndex < parts.length - 1 && (
            <ArrowLeftIcon className="event-fixtures-list__stages__header__navigate-btn__icon" />
          )}
        </div>
      </div>

      <div className="event-fixtures-list__stages__body">
        {subparts.length ? (
          <Stages
            ev={ev}
            parts={subparts.sort((a, b) => {
              return a.order - b.order;
            })}
          />
        ) : (
          <Stage part={selectedPart} ev={ev} />
        )}
      </div>
    </div>
  );
}

export default function EventFixturesList({ ev }) {
  const selectionSet = `
    fixtures{
      id
      label
      scheduledStart
      scheduledEnd
      actualStart
      actualEnd
      groupByDate
      totalLegs
      eventPartId
      eventPart{
        id
      }
      roles{
        id
        order
        type{
          id
          name
        }
        organization{
          id
          name
          handle
          profilePhoto{
            id
            filename
          }
        }
        profile{
          id
          name
          handle
          profilePhoto{
            id
            filename
          }
        }
        placement{
          id
          order
          fixture{
            id
            label
          }
          eventPart{
            id
            label
          }
        }
      }
    }
    parts{
      id
      order
      type
      label
      partOf{
        id
      }
      roles{
        id
        organization{
          id
          name
        }
        profile{
          id
          name
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(
    EVENT({
      selectionSet,
    }),
    { variables: { id: ev.id } }
  );

  const parts = data?.event?.parts
    ?.filter((p) => !p.partOf)
    .sort((a, b) => {
      return a.order - b.order;
    });

  return (
    <Card className="event-fixtures-list">
      <CardTitle title={"FIXTURES"} />
      {loading ? (
        <div className="App-loading-wrapper">
          <Spinner />
        </div>
      ) : (
        <Stages ev={data.event} parts={parts} />
      )}
    </Card>
  );
}
